import { isDev, isStaging } from '@respell/utils/config';
import { deepMerge } from '@respell/utils/datatypes/object';

// Environment-specific config
// NOTE: Secrets must be defined in nuxt.config.js:vite.define['process.env'] as well to be exposed via process.env
const env = isDev
  ? {
    appUrl: 'http://localhost:8080',
    apiUrl: 'http://localhost:8080',
    cdnUrl: 'http://localhost:3000/file',
    services: {
      slack: {
        redirectUri: 'http://localhost:8080/api/slack',
      },
    },
  }
  : isStaging
    ? {
      appUrl: 'https://staging.respell.ai',
      apiUrl: 'https://staging-api.respell.ai',
      cdnUrl: 'https://staging-api.respell.ai/file',
      services: {
        slack: {
          redirectUri: 'https://staging.respell.ai/api/slack',
        },
      },
    }
    : {
      appUrl: 'https://app.respell.ai',
      apiUrl: 'https://api.respell.ai',
      cdnUrl: 'https://cdn.respell.ai/file',
      services: {
        slack: {
          redirectUri: 'https://app.respell.ai/api/slack',
        },
      },
    };

// Shared config
export const CONFIG = deepMerge(env, {
  service: 'CLIENT',
  // environment: ENVIRONMENT,
  tutorialSpellId: process.env.TUTORIAL_SPELL_ID,
  services: {
    google: {
      clientId: process.env.GOOGLE_CLIENT_ID,
      apiKey: process.env.GOOGLE_API_KEY,
      projectId: process.env.GOOGLE_PROJECT_ID,
    },
    posthog: {
      appToken: process.env.POST_HOG_TOKEN,
    },
    slack: {
      clientId: process.env.SLACK_CLIENT_ID,
    },
    june: {
      writeKey: process.env.JUNE_WRITE_KEY,
    },
    amplitude: {
      apiKey: process.env.AMPLITUDE_API_KEY,
    },
    sentry: {
      dsn: process.env.CLIENT_SENTRY_DSN,
    },
    stripe: {
      publicKey: process.env.STRIPE_PUBLIC_KEY,
    },
  },
});
